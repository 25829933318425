import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['toggle'];
  static targets = ['element'];
  static values = { cookie: String };

  toggle(event) {
    event.preventDefault();

    for(let b of this.elementTargets) {
      if(b.dataset.classTogglerClasses) {
        var classes = b.dataset.classTogglerClasses.split(',');
        b.classList.toggle(...classes);
      }
    }

    this.toggleCookie();
  }

  toggleCookie() {
    if(!this.hasCookieValue) { return; }

    var currentValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(this.cookieValue + '='))
      ?.split('=')[1];

    if(currentValue == 'on') {
      this.removeCookie();
    } else {
      this.setCookie();
    }
  }

  setCookie() {
    document.cookie = this.cookieValue + '=on; path=/; SameSite=None; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  }

  removeCookie() {
    document.cookie = this.cookieValue + '=; path=/; SameSite=None; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  }
}
